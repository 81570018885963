import {
  BlockEntityHydrated,
  ClientPrintServiceEntity,
  Dictionary,
  EntityId,
  PageEntity,
  PrintServiceConfigurationEntry,
  PrintServiceEntity,
  PrintServiceProductConfigurationItem,
  PrintServiceProductEntity,
} from "@jackfruit/common"
import { call, put, takeEvery } from "@redux-saga/core/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { flatten, uniq, uniqBy } from "lodash"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { blocks as blocksStore } from "~/redux/state/blocks"
import { pages as pagesStore } from "~/redux/state/pages"
import { printServiceProducts as printServiceProductsStore } from "~/redux/state/printServiceProducts"
import { printServices as printServicesStore } from "~/redux/state/printServices"
import { PrinticularApi } from "~/services/PrinticularApi"
import { getPageCountryCode, scrollTo } from "~/services/Utils"
import { actions, BootstrapPagePayload } from "../process"
import { carts } from "../state/carts"
import { clients } from "../state/client"
import { clientPrintServices } from "../state/clientPrintServices"
import { orderSummaries, orderSummariesFactory } from "../state/orderSummaries"
import { pageSessions, pageSessionsActions } from "../state/pageSessions"
import { printServiceProductImages as printServiceProductImagesStore } from "../state/printServiceProductImages"
import { printServiceProductCategories as printServiceProductCategoriesStore } from "../state/printServiceProductCategory"
import { printServiceProductPrices } from "../state/printServiceProductPrices"
import { templateTextColors } from "../state/template-text-colors"
import { templateTextFonts } from "../state/template-text-fonts"
import { getApi } from "./api"
import { getBlock, getTemplateBlocksForPage } from "./blocks"
import { getPage } from "./page"
import { getPageSession } from "./pageSession"
import { getPrintServiceProducts } from "./printServiceProducts"
import { getPrintService } from "./printServices"
import { downloadproductTemplateTags } from "./processDownloadTags"
import { v4 as uuidv4 } from "uuid"

export function* watchBootstrapPage() {
  yield takeEvery(actions.bootstrapPage.type, bootstrapPage)
}

export function* bootstrapPage(action: PayloadAction<BootstrapPagePayload>) {
  const { id, toast } = action.payload

  yield call(bootLocalConfig, id)
  yield call(bootRemoteConfig, { id, toast })
}

function* bootLocalConfig(id: EntityId) {
  const pageSession: PageSessionEntity = yield call(getPageSession, {
    pageSessionId: id,
  })
  const orderSummary = orderSummariesFactory({ id })
  yield put(orderSummaries.actions.upsertOne(orderSummary))
  // reset page session
  yield put(
    pageSessions.actions.updateOne({
      id: pageSession.id,
      changes: {
        uploadIds: [],
      },
    })
  )
  // reset cart
  yield put(
    carts.actions.updateOne({
      id: pageSession.cartId,
      changes: {
        couponCode: "",
        couponCodeStatus: "pending",
        couponType: "coupon",
        couponCodeTitle: "",
        lineItemIds: [],
        nonce: uuidv4(),
        paymentIntent: undefined,
      },
    })
  )
}

function* bootRemoteConfig(payload: BootstrapPagePayload) {
  const { id, toast } = payload

  const printicularApi: PrinticularApi = yield call(getApi)
  const page: PageEntity = yield call(getPage, { pageId: id })
  const pageSession: PageSessionEntity = yield call(getPageSession, {
    pageSessionId: id,
  })

  const templateBlocks: BlockEntityHydrated[] = yield call(
    getTemplateBlocksForPage,
    {
      pageId: page.id,
    }
  )

  const allTemplateBlockPrintServiceConfigs = flatten(
    templateBlocks.map(templateBlock => {
      return flatten([
        ...templateBlock.template.printServices.delivery,
        ...templateBlock.template.printServices.pickup,
      ])
    })
  )

  // Fetch print service details from remote api for current page
  const pagePrintServiceConfigs = flatten([
    ...page.printServices.delivery,
    ...page.printServices.pickup,
  ])

  const allPrintServiceConfigs = uniqBy(
    [...allTemplateBlockPrintServiceConfigs, ...pagePrintServiceConfigs],
    "id"
  )

  //process print service config for page via API v2
  const {
    client,
    clientPrintServices: clientPrintServicesRaw,
    colors,
    fonts,
    printServices,
    products,
    productImages,
    productPrices,
    productCategories,
  } = yield call(() =>
    printicularApi.getPrintServiceDetails(
      allPrintServiceConfigs.map(config => config.id),
      getPageCountryCode(page.territories.pickup, page.territories.delivery)
    )
  )
  yield put(clients.actions.addOne(client))

  const clientPrintServiceEntities = clientPrintServicesRaw.reduce(
    (
      entity: Dictionary<ClientPrintServiceEntity>,
      service: ClientPrintServiceEntity
    ) => {
      entity[service.id] = { ...service, clients: client.id }
      return entity
    },
    {} as Dictionary<ClientPrintServiceEntity>
  )

  if (colors.length > 0) {
    yield put(templateTextColors.actions.addMany(colors))
  }

  if (fonts.length > 0) {
    yield put(templateTextFonts.actions.addMany(fonts))
  }

  yield put(
    printServiceProductCategoriesStore.actions.addMany(productCategories)
  )

  // re assign server id to remote id for compatibility
  products.forEach((product: any, index: any, original: any) => {
    original[index].remoteId = product.id
    original[index].printServiceProductImages = product.productImages ?? []
    original[index].printServiceId = Number(product.printService)
  })

  yield put(printServiceProductsStore.actions.addMany(products))
  yield put(printServiceProductImagesStore.actions.addMany(productImages))
  yield put(printServiceProductPrices.actions.addMany(productPrices))

  printServices.forEach((printService: any) => {
    printService.remoteId = printService.id
    printService.clientId = client.id
    printService.printServiceProducts = products
      .filter((product: PrintServiceProductEntity) => {
        return product.printServiceId === printService.id
      })
      .map((product: PrintServiceProductEntity) => product.id)
  })

  yield put(printServicesStore.actions.addMany(printServices))

  // create auto generated config when required
  for (let printServiceConfig of pagePrintServiceConfigs) {
    if (printServiceConfig.hasProductsAutoEnabled) {
      const productConfig: PrintServiceProductConfigurationItem = yield call(
        generateProductConfig,
        printServiceConfig
      )

      const refreshedPage: PageEntity = yield call(getPage, { pageId: page.id })
      const newProductConfig = {
        ...refreshedPage.products,
        [printServiceConfig.fulfillmentType]: {
          ...refreshedPage.products[printServiceConfig.fulfillmentType],
          [printServiceConfig.id]: {
            ...productConfig,
          },
        },
      }

      yield put(
        pagesStore.actions.updateOne({
          id: page.id,
          changes: { products: newProductConfig },
        })
      )
    }
  }

  const requiredTags = []
  // generate configuration for template blocks
  for (let templateBlock of templateBlocks) {
    requiredTags.push(...templateBlock.template.tags)
    const blockPrintServiceConfigs = flatten([
      ...templateBlock.template.printServices.delivery,
      ...templateBlock.template.printServices.pickup,
    ])

    for (let printServiceConfig of blockPrintServiceConfigs) {
      if (printServiceConfig.hasProductsAutoEnabled) {
        const productConfig: PrintServiceProductConfigurationItem = yield call(
          generateProductConfig,
          printServiceConfig
        )

        const refreshedBlock: BlockEntityHydrated = yield call(getBlock, {
          blockId: templateBlock.id,
        })

        const newProductConfig = {
          ...refreshedBlock.template.products,
          [printServiceConfig.fulfillmentType]: {
            ...refreshedBlock.template.products[
              printServiceConfig.fulfillmentType
            ],
            [printServiceConfig.id]: {
              ...productConfig,
            },
          },
        }

        yield put(
          blocksStore.actions.updateOne({
            id: templateBlock.id,
            changes: {
              template: {
                ...refreshedBlock.template,
                products: newProductConfig,
              },
            },
          })
        )
      }
    }
  }
  // fetch template tags for page
  const uniqTags = uniq(requiredTags)
  if (uniqTags.length > 0) {
    yield call(downloadproductTemplateTags, uniqTags)
  }

  yield put(clientPrintServices.actions.addMany(clientPrintServiceEntities))
  yield put(pageSessionsActions.setIsReady(pageSession.id, true))

  // Upload images from url parameter
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const imageParam = urlParams.get("image")
  const imageURIs: string[] = imageParam ? imageParam.split(",") : []

  yield put(
    actions.uploadUriImages({
      imageURIs,
      pageId: id,
      toast,
      scrollTo,
      nextBlockName: "cart",
    })
  )
}

function* generateProductConfig(
  printServiceConfig: PrintServiceConfigurationEntry
) {
  const printService: PrintServiceEntity = yield call(getPrintService, {
    id: printServiceConfig.id,
  })

  const products: PrintServiceProductEntity[] = yield call(
    getPrintServiceProducts,
    {
      ids: printService.printServiceProducts,
    }
  )

  // generate product configuration
  const productConfig = products.reduce<any>((newConfig, product) => {
    newConfig[product.id] = {
      id: product.id,
      internalId: product.id,
      position: product.position,
      isDefaultSquareProduct: 0,
      isDefaultRectangleProduct: 0,
    }

    return newConfig
  }, {})

  return productConfig
}
