import { AvailableLanguages, EntityId } from "@jackfruit/common"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LocalStorageKeys } from "~/interfaces/entities/LocalStorageKeys"

export interface ApplicationState extends LocalStorageKeys {
  id: EntityId
  appVersion: string
  name: string
  baseUrl: string
  hasBootedLocalConfig: boolean
  hasBootedRemoteConfig: boolean
  hasBootedCurrentPage: boolean
  currentPageId: EntityId
  termsAndConditionsUrl: string | null
  privacyPolicyUrl: string | null
  iosStoreUrl: string
  iosStoreId: string
  iosStoreName: string
  iosStoreRating: number
  iosStoreRatingCount: string
  iosDisplayStoreBannerOnMobile: boolean
  androidStoreUrl: string
  androidStoreName: string
  androidStoreRating: number
  androidStoreRatingCount: string
  androidDisplayStoreBannerOnMobile: boolean
  blockOrderSummaryAppInstallEnabled: boolean
  blockOrderSummaryAppInstallQrCode: boolean
  blockOrderSummaryAppInstallQrCodeUrl: string
  blockOrderSummaryAppInstallAppScreenImage?: {
    path: string
  }
  blockOrderSummaryAppInstallContent: string
  lastOrderId: EntityId
  facebookUrl: string
  instagramUrl: string
  youtubeUrl: string
  twitterUrl: string
  defaultLocale: string
  defaultCurrency: string
  primaryColor: string
  language: AvailableLanguages
}

const initialState: ApplicationState = {
  id: 0,
  appVersion: "",
  name: "",
  baseUrl: "",
  hasBootedLocalConfig: false,
  hasBootedRemoteConfig: false,
  hasBootedCurrentPage: false,
  localStorageFulfillmentKey: "",
  localStorageAppBannerKey: "",
  localStorageCartKey: "",
  localStorageUserKey: "",
  localStorageAddressKey: "",
  localStorageAuthTokenKey: "",
  currentPageId: 0,
  termsAndConditionsUrl: "",
  privacyPolicyUrl: "",
  iosStoreUrl: "",
  iosStoreName: "",
  iosStoreRating: 5,
  iosStoreRatingCount: "",
  iosDisplayStoreBannerOnMobile: false,
  androidStoreUrl: "",
  androidStoreName: "",
  androidStoreRating: 5,
  androidStoreRatingCount: "",
  androidDisplayStoreBannerOnMobile: false,
  blockOrderSummaryAppInstallEnabled: false,
  blockOrderSummaryAppInstallQrCode: false,
  blockOrderSummaryAppInstallQrCodeUrl: "",
  blockOrderSummaryAppInstallAppScreenImage: undefined,
  blockOrderSummaryAppInstallContent: "",
  lastOrderId: 0,
  facebookUrl: "",
  instagramUrl: "",
  youtubeUrl: "",
  twitterUrl: "",
  defaultLocale: "",
  defaultCurrency: "",
  language: "en-US",
  primaryColor: "",
}

export const application = createSlice({
  name: "application",
  initialState,
  reducers: {
    // BOOT
    updateHasBootedLocalConfig(
      state: ApplicationState,
      action: PayloadAction<boolean>
    ) {
      state.hasBootedLocalConfig = action.payload
    },
    updateHasBootedRemoteConfig(
      state: ApplicationState,
      action: PayloadAction<boolean>
    ) {
      state.hasBootedRemoteConfig = action.payload
    },
    setLocalStorageKeys(
      state: ApplicationState,
      action: PayloadAction<{
        localStorageAppBannerKey: string
        localStorageFulfillmentKey: string
        localStorageCartKey: string
        localStorageUserKey: string
        localStorageAddressKey: string
        localStorageAuthTokenKey: string
      }>
    ) {
      const {
        localStorageAppBannerKey,
        localStorageFulfillmentKey,
        localStorageCartKey,
        localStorageUserKey,
        localStorageAddressKey,
        localStorageAuthTokenKey,
      } = action.payload

      state.localStorageAppBannerKey = localStorageAppBannerKey
      state.localStorageFulfillmentKey = localStorageFulfillmentKey
      state.localStorageCartKey = localStorageCartKey
      state.localStorageUserKey = localStorageUserKey
      state.localStorageAddressKey = localStorageAddressKey
      state.localStorageAuthTokenKey = localStorageAuthTokenKey
    },

    // PAGES
    setHasBootedCurrentPage(
      state: ApplicationState,
      action: PayloadAction<boolean>
    ) {
      state.hasBootedCurrentPage = action.payload
    },
    setCurrentPageId(state: ApplicationState, action: PayloadAction<EntityId>) {
      state.currentPageId = action.payload
    },
    // Legal URLs
    setLegalUrls(
      state: ApplicationState,
      action: PayloadAction<{
        termsAndConditionsUrl: string | null
        privacyPolicyUrl: string | null
      }>
    ) {
      const { termsAndConditionsUrl, privacyPolicyUrl } = action.payload

      state.termsAndConditionsUrl = termsAndConditionsUrl
      state.privacyPolicyUrl = privacyPolicyUrl
    },

    // Media social links
    setSocialMediaLinks(
      state: ApplicationState,
      action: PayloadAction<{
        facebookUrl: string
        instagramUrl: string
        youtubeUrl: string
        twitterUrl: string
      }>
    ) {
      const { facebookUrl, instagramUrl, youtubeUrl, twitterUrl } =
        action.payload

      state.facebookUrl = facebookUrl
      state.instagramUrl = instagramUrl
      state.youtubeUrl = youtubeUrl
      state.twitterUrl = twitterUrl
    },

    // App Store Urls
    setAppStoreDetails(
      state: ApplicationState,
      action: PayloadAction<{
        iosStoreUrl: string
        iosStoreName: string
        iosStoreRating: number
        iosStoreRatingCount: string
        iosDisplayStoreBannerOnMobile: boolean
        androidStoreUrl: string
        androidStoreName: string
        androidStoreRating: number
        androidStoreRatingCount: string
        androidDisplayStoreBannerOnMobile: boolean
      }>
    ) {
      const {
        iosStoreUrl,
        iosStoreName,
        iosStoreRating,
        iosStoreRatingCount,
        iosDisplayStoreBannerOnMobile,
        androidStoreUrl,
        androidStoreName,
        androidStoreRating,
        androidStoreRatingCount,
        androidDisplayStoreBannerOnMobile,
      } = action.payload

      state.iosStoreUrl = iosStoreUrl
      state.iosStoreName = iosStoreName
      state.iosStoreRating = iosStoreRating
      state.iosStoreRatingCount = iosStoreRatingCount
      state.iosDisplayStoreBannerOnMobile = iosDisplayStoreBannerOnMobile
      state.androidStoreUrl = androidStoreUrl
      state.androidStoreName = androidStoreName
      state.androidStoreRating = androidStoreRating
      state.androidStoreRatingCount = androidStoreRatingCount
      state.androidDisplayStoreBannerOnMobile =
        androidDisplayStoreBannerOnMobile
    },

    // Order summary block app install content
    setOrderSummaryBlockAppInstallContent(
      state: ApplicationState,
      action: PayloadAction<{
        blockOrderSummaryAppInstallEnabled: boolean
        blockOrderSummaryAppInstallQrCode: boolean
        blockOrderSummaryAppInstallQrCodeUrl: string
        blockOrderSummaryAppInstallAppScreenImage: EntityId
        blockOrderSummaryAppInstallContent: string
      }>
    ) {
      const {
        blockOrderSummaryAppInstallEnabled,
        blockOrderSummaryAppInstallQrCode,
        blockOrderSummaryAppInstallQrCodeUrl,
        blockOrderSummaryAppInstallAppScreenImage,
        blockOrderSummaryAppInstallContent,
      } = action.payload

      state.blockOrderSummaryAppInstallEnabled =
        blockOrderSummaryAppInstallEnabled
      state.blockOrderSummaryAppInstallQrCode =
        blockOrderSummaryAppInstallQrCode
      state.blockOrderSummaryAppInstallQrCodeUrl =
        blockOrderSummaryAppInstallQrCodeUrl
      state.blockOrderSummaryAppInstallAppScreenImage =
        blockOrderSummaryAppInstallAppScreenImage
      state.blockOrderSummaryAppInstallContent =
        blockOrderSummaryAppInstallContent
    },

    //Localization
    setLocalization(
      state: ApplicationState,
      action: PayloadAction<{
        defaultLocale: string
        defaultCurrency: string
        language: AvailableLanguages
      }>
    ) {
      const { defaultLocale, defaultCurrency, language } = action.payload
      state.defaultCurrency = defaultCurrency
      state.defaultLocale = defaultLocale
      state.language = language
    },

    setApplicationData: (
      state: ApplicationState,
      action: PayloadAction<{
        name: string
        id: EntityId
        baseUrl: string
        appVersion: string
        primaryColor: string
      }>
    ) => {
      const { name, id, baseUrl, appVersion, primaryColor } = action.payload
      state.name = name
      state.id = id
      state.baseUrl = baseUrl
      state.appVersion = appVersion
      state.primaryColor = primaryColor
    },
    // Last OrderId
    setLastOrderId: (
      state: ApplicationState,
      action: PayloadAction<{
        orderId: EntityId
      }>
    ) => {
      const { orderId } = action.payload
      state.lastOrderId = orderId
    },
  },
})
