import { SagaIterator } from "@redux-saga/types"
import { call, put, select, takeLatest } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { StoreEntity } from "~/interfaces/entities/Store"
import { actions as process } from "../process"
import { carts } from "../state/carts"
import { storesSelectors } from "../state/stores"
import { RootState } from "../store"
import { getCurrentCart } from "./cart"
import { selectOrderData, sendOrderToPrinticular } from "./helpers"
import { AnalyticAction, processAnalyticEvents } from "./processAnalyticEvents"
import { v4 as uuidv4 } from "uuid"
import { Gtm } from "~/services/Gtm"

export default function* watchProcessCouponCode() {
  yield takeLatest(process.applyCouponCode.type, processCouponCode)
}

function* processCouponCode(): SagaIterator<any> {
  const orderData = yield call(selectOrderData)

  const pageCart: CartEntity = yield call(getCurrentCart)

  const store: StoreEntity = yield select((state: RootState) =>
    storesSelectors.selectById(state, pageCart.storeId)
  )

  try {
    yield put(
      carts.actions.updateOne({
        id: pageCart.id,
        changes: {
          couponCodeStatus: "loading",
        },
      })
    )

    const printicularOrder = yield call(sendOrderToPrinticular, "dryRun")

    // If coupon code was indeed used (not empty)
    if (printicularOrder.couponCode) {
      yield put(
        carts.actions.updateOne({
          id: pageCart.id,
          changes: {
            couponCodeStatus: "accepted",
            couponType: "coupon",
          },
        })
      )

      Gtm.fireApplyCouponEvent(
        store?.name,
        printicularOrder.discount,
        printicularOrder.total,
        printicularOrder.couponCode
      )

      yield call(
        processAnalyticEvents,
        AnalyticAction({
          eventType: "couponApplied",
          data: {
            order: printicularOrder,
            store,
          },
        })
      )
    } else if (Boolean(printicularOrder.giftCertificate)) {
      yield put(
        carts.actions.updateOne({
          id: pageCart.id,
          changes: {
            couponCodeStatus: "accepted",
            couponType: "gift card",
          },
        })
      )
    } else {
      yield put(
        carts.actions.updateOne({
          id: pageCart.id,
          changes: {
            couponCodeStatus: "pending",
          },
        })
      )
    }
    //prevent duplicate nonce if the user applies a coupon
    //after creating an order
    yield put(
      carts.actions.updateOne({
        id: pageCart.id,
        changes: {
          nonce: uuidv4(),
          paymentIntent: undefined,
        },
      })
    )

    yield put(
      process.updateOrderSummary({
        reason: "update coupon code",
        reasonType: "updateCouponCode",
      })
    )
  } catch (error: any) {
    console.error(error)

    Gtm.fireInvalidCouponEvent(
      store?.name,
      orderData.total,
      orderData.couponCode
    )

    yield call(
      processAnalyticEvents,
      AnalyticAction({
        eventType: "couponInvalid",
        data: {
          order: orderData,
          store,
        },
      })
    )

    yield put(
      carts.actions.updateOne({
        id: pageCart.id,
        changes: {
          couponCode: "",
          couponCodeStatus: "rejected",
          couponCodeTitle: error.errors[0].title,
        },
      })
    )
  }
}
