import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
} from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { t } from "i18next"
import { ApiException } from "~/interfaces/entities/ApiException"
import { AuthResume } from "~/interfaces/entities/AuthResume"
import { AuthUser } from "~/interfaces/entities/AuthUser"
import {
  getExceptionDisplayedMessage,
  isAuthFailed,
} from "~/services/ApiExceptionHelper"
import { PrinticularApi } from "~/services/PrinticularApi"
import {
  auth,
  LoginPayload,
  LoginSuccessPayload,
  LogoutPayload,
} from "../state/auth"
import { getApi } from "./api"
import { AnalyticAction, processAnalyticEvents } from "./processAnalyticEvents"
import {
  loadAuthDataFromLocalStorage,
  restoreCartDataFromLocalStorage,
  restoreCheckoutDataFromLocalStorage,
  saveAuthDataToLocalStorage,
} from "./processRestoreDataFromLocalStorage"

const TOKEN_REFRESH_DELAY = 1000 * 60 * 14 // 14 minutes

// ========================================================
// login user
// ========================================================
export function* watchLogin() {
  yield takeEvery(auth.actions.login.type, processLogin)
}

function* processLogin(action: PayloadAction<LoginPayload>): SagaIterator {
  try {
    const { login, password, toast } = action.payload

    const api: PrinticularApi = yield call(getApi)
    const authUser: AuthUser = yield call([api, "login"], {
      login,
      password,
    })

    yield put(auth.actions.loginSuccess({ authUser, toast }))
  } catch (error) {
    const exceptionError = error as ApiException

    let message = t("redux.saga.processAuth.processLogin.LoginFailed")
    if (isAuthFailed(exceptionError)) {
      message = getExceptionDisplayedMessage(exceptionError)
    }
    yield put(auth.actions.loginFailed(message))
  }
}

// ========================================================
// login user silently
// ========================================================
export function* watchLoginSilently() {
  yield takeEvery(auth.actions.loginSilently.type, processLoginSilently)
}

function* processLoginSilently(): SagaIterator {
  const { isAuthenticated, isAuthenticating } = yield select(
    state => state.auth
  )

  try {
    const token = yield call(loadAuthDataFromLocalStorage)

    if (token) {
      if (!isAuthenticated) {
        yield put(auth.actions.setIsLoading(true))
      }

      const api: PrinticularApi = yield call(getApi)
      const authUser: AuthUser = yield call([api, "loginSilently"], {
        token,
      })

      yield put(auth.actions.loginSuccess({ authUser }))
    } else {
      yield put(auth.actions.setIsAuthenticating(false))
    }
  } catch (error) {
    // do not logout user for connectivity issues
    if (isAuthFailed(error as ApiException) && isAuthenticating) {
      yield put(auth.actions.logout({}))
    }
  }
}

// ========================================================
// save user informations once logged in
// ========================================================
export function* watchLoginSuccess() {
  yield takeEvery(auth.actions.loginSuccess.type, processLoginSuccess)
}

function* processLoginSuccess(
  action: PayloadAction<LoginSuccessPayload>
): SagaIterator {
  const { authUser, navigate, toast } = action.payload

  try {
    // save token to local storage for reuse
    yield call(saveAuthDataToLocalStorage, { token: authUser.loginToken })
    // restore user local storage
    yield call(restoreCheckoutDataFromLocalStorage)
    yield call(restoreCartDataFromLocalStorage)

    // fire login event for analytic
    yield call(
      processAnalyticEvents,
      AnalyticAction({
        eventType: "login",
        data: {},
      })
    )

    const title = t("redux.saga.processAuth.processLoginSuccess.Title", {
      name: authUser.name,
    })
    const description = t(
      "redux.saga.processAuth.processLoginSuccess.Description"
    )

    navigate?.("/account")
    toast?.({
      title,
      description,
      status: "info",
      duration: 3000,
      isClosable: true,
    })
  } catch (error) {
    const errorMessage = t(
      "redux.saga.processAuth.processLoginSuccess.LoginFailed"
    )
    yield put(auth.actions.loginFailed(errorMessage))
  }
}

// ========================================================
// refresh user session
// ========================================================
export function* watchLoginResume() {
  while (true) {
    yield take(auth.actions.loginSuccess.type)
    // race will automatically cancel the running task
    // if any of the other running one get fulfilled
    // here logout or loginFailed are racing against loginResume
    yield race([
      call(processLoginResume),
      take([auth.actions.logout.type, auth.actions.loginFailed.type]),
    ])
  }
}

export function* processLoginResume(): SagaIterator {
  while (true) {
    try {
      yield delay(TOKEN_REFRESH_DELAY)
      const api: PrinticularApi = yield call(getApi)
      const authResume: AuthResume = yield call([api, "resumeLogin"])

      yield put(auth.actions.loginResumeSuccess(authResume))
      yield call(saveAuthDataToLocalStorage, { token: authResume.token })
    } catch (error) {
      yield put(auth.actions.logout({}))
    }
  }
}

// ========================================================
// clear local storage on user logged out
// ========================================================
export function* watchLogout() {
  yield takeEvery(auth.actions.logout.type, processLogout)
}

function* processLogout(
  action: PayloadAction<LogoutPayload>
): SagaIterator<void> {
  const { toast } = action.payload
  yield call(saveAuthDataToLocalStorage, { token: "" })

  const title = t("redux.saga.processAuth.processLogout.Title")
  const description = t("redux.saga.processAuth.processLogout.Description")

  toast?.({
    title,
    description,
    status: "info",
    duration: 3000,
    isClosable: true,
  })
}
